import React from 'react'

const PlayIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.215 23.398V.507l15.492 11.445z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export default PlayIcon
