// @flow

import React from 'react'
import './styles.scss'

import PlayIcon from '../Icons/PlayIcon'

type Props = {
  onClick?: () => void,
  children?: any,
}

const noop = () => {}

const PlayButton = ({ onClick = noop, children = 'Play' }: Props) => (
  <button className="play-button" onClick={onClick}>
    <PlayIcon className="d-block mx-auto my-1" />
    {children}
  </button>
)

export default PlayButton
