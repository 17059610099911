// @flow

import React, { type Node } from 'react'
import './styles.scss'

type Props = {
  className: string,
  icon: Node,
  children?: Node,
  render: (props: Object) => Node,
}

const ButtonAlternative = ({
  className,
  icon,
  children,
  render = renderButton,
  ...props
}: Props) => {
  const mergedClassNames = ['button input-group']

  if (className) {
    mergedClassNames.push(className)
  }

  if (icon) {
    mergedClassNames.push('has-icon')
  }

  return (
    <div className={mergedClassNames.join(' ')}>
      {render({
        className: 'btn',
        children,
        ...props,
      })}
      {icon && <span className="input-group-append">{icon}</span>}
    </div>
  )
}

export default ButtonAlternative

function renderButton(props) {
  return <button {...props} />
}
