// @flow

import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import whitepaper from '../../static/Mainframe-Whitepaper.pdf'

import ArrowIcon from '../components/Icons/ArrowIcon'
import BlogArea from '../components/BlogArea'
import VideoSection from '../components/VideoSection'

import './homepage.scss'

import mainframeMovement from '../images/home/mainframe-future.png'
import rebrand from '../images/home/MainframeWebsiteGraphic.svg'

import thumb1 from '../images/home/video1.png'
import thumb2 from '../images/home/video2.png'
import thumb3 from '../images/home/video3.png'
import thumb4 from '../images/home/video4.png'
import thumb5 from '../images/home/video5.png'
import {
  InfoBox,
  InfoBoxGrid,
  InfoBoxImage,
  InfoBoxText,
} from 'shared-components'
// import Button from '../components/ButtonAlternative'
import InvestImg from '../images/home/invest.png'
import Button from '../components/ButtonAlternative'

export default class HomePage extends Component<{}> {
  videos = [
    {
      id: 'VcR6gTkW7g8',
      image: thumb5,
      title: 'Mainframe Lending Protocol Introduction',
      category: 'Mainframe Introduction',
    },
    {
      id: 'wXEozViInLo',
      image: thumb2,
      title: 'Rosella Node! [Parody]',
      category: 'Learn the crazy language of Web3!',
    },
    {
      id: 'qP6TMi11Sfg',
      image: thumb3,
      title: 'The Churchill War Rooms',
      category: 'Freedom stories',
    },
    {
      id: 'YZEHb1_dvUI',
      image: thumb4,
      title: 'The Warsaw Uprising Monument',
      category: 'Freedom stories',
    },
  ]

  modal: any

  openModal = () => {
    this.modal.open()
  }

  setModalRef = (e: any) => {
    this.modal = e
  }

  render() {
    return (
      <Layout useContainer={false}>
        <Helmet title="Mainframe - Fixed-rate crypto lending" />
        <div className="home-page">
          {/* <div className="top-area container">
            <div className="header-container">
              <h1>Be the bank</h1>
              <h2>
                Fixed-rate borrowing against crypto. {' '}
                <br className="d-none d-sm-block" />
                Users get instant liquidity to multiply their investment.
              </h2>
            </div>
          </div> */}
          {/* <div className="session session-outwards area-video">
            <div className="container px-0">
              <VideoSection videos={this.videos} />
            </div>
          </div> */}
          {/*<div className="session session-outwards">*/}
          {/*  <div className="container">*/}
          {/*    /!*<MainframeOSCard />*!/*/}
          {/*    <InfoBoxGrid className="marketplace-card">*/}
          {/*      <InfoBox>*/}
          {/*        <InfoBoxImage src={InvestImg} />*/}
          {/*        <InfoBoxText className="order-lg-first">*/}
          {/*          <h3>Invest and earn</h3>*/}
          {/*          <p>*/}
          {/*            Mainframe{"'"}s marketplace offers lenders control and*/}
          {/*            liquidity of their investment while delivering the best*/}
          {/*            available rates to borrowers.*/}
          {/*          </p>*/}
          {/*          <Button*/}
          {/*            icon={<ArrowIcon />}*/}
          {/*            className="mb-1 d-flex w-100"*/}
          {/*            render={props => (*/}
          {/*              <Link {...props} to="/roadmap">*/}
          {/*                <span className="d-none d-md-block">*/}
          {/*                  See the roadmap*/}
          {/*                </span>*/}
          {/*                <span className="d-block d-md-none">Roadmap</span>*/}
          {/*              </Link>*/}
          {/*            )}*/}
          {/*          />*/}
          {/*        </InfoBoxText>*/}
          {/*      </InfoBox>*/}
          {/*    </InfoBoxGrid>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="session mainframe-revolution-section session-inwards">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-5 text">
                  <h3>Mainframe is now Hifi</h3>
                  <p className="d-block mt-5">
                    Introducing our new visual brand that encompasses our mission of delivering a high fidelity experience to decentralized finance.
                    <br className="d-none d-sm-block" />
                    Head over to hifi.finance to learn more.
                  </p>
                  {/*<a*/}
                  {/*  href="https://blog.mainframe.com/mainframe-lending-protocol-a-primer-c8e5241efce7"*/}
                  {/*  target="_blank"*/}
                  {/*  rel="noopener noreferrer"*/}
                  {/*  className="d-block mt-3 pt-3">*/}
                  {/*  Download Whitepaper <ArrowIcon />*/}
                  {/*</a>*/}
                  <a href={'//hifi.finance'}><Button
                      icon={<ArrowIcon />}
                      className="mb-1 d-flex w-75">
                     {/*<span className="d-none d-md-block">*/}

                     {/*</span>*/}
                    Go To Hifi.finance
                  </Button></a>

                </div>

                <div className="col-12 col-lg-7">
                  <img src={rebrand} alt="Mainframe’s rebrand" />
                </div>
              </div>
            </div>
          </div>
          {/*<div className="session session-outwards area-video">*/}
          {/*  <div className="container px-0">*/}
          {/*    <VideoSection videos={this.videos} />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="session area-media gray-bg session-inwards">*/}
          {/*  <div className="content" />*/}
          {/*</div>*/}
          <BlogArea className="session session-outwards-top" />
        </div>
      </Layout>
    )
  }
}
