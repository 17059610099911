// @flow

import React from 'react'
import './styles.scss'

import { VideoModal } from 'shared-components'
import PlayButton from '../PlayButton'
// import { Carousel, CarouselSlide } from '../Carousel'
// import VideoItem from './VideoItem'

export type Video = {
  id: string,
  title: string,
  category: string,
  image: string,
}

type Props = {
  videos: Array<Video>,
}

type State = {
  currentVideo: ?Video,
  isOpen: boolean,
}

export default class VideoCarousel extends React.Component<Props, State> {
  state = {
    currentVideo: null,
    isOpen: false,
  }

  playVideo = (video: Video): void =>
    this.setState({
      currentVideo: video,
      isOpen: true,
    })

  stopVideo = (): void =>
    this.setState({
      isOpen: false,
    })

  render() {
    const { currentVideo, isOpen } = this.state
    const featuredVideo: Video = this.props.videos[0]
    // const videoList: Array<Video> = this.props.videos.slice(1, 4)

    return (
      <div className="video-section">
        {currentVideo && (
          <VideoModal
            videoId={currentVideo.id}
            isOpen={isOpen}
            onClose={this.stopVideo}
          />
        )}

        <div className="video-section-current">
          <img
            className="h-100"
            src={featuredVideo.image}
            alt={featuredVideo.title}
          />
          <PlayButton onClick={() => this.playVideo(featuredVideo)} />
        </div>

        {/*<div className="video-row">*/}
        {/*  {videoList.map(video => (*/}
        {/*    <VideoItem key={video.id} video={video} onClick={this.playVideo} />*/}
        {/*  ))}*/}
        {/*</div>*/}

        {/*<Carousel>*/}
        {/*  {this.props.videos.slice(0, 3).map(video => (*/}
        {/*    <CarouselSlide key={video.id}>*/}
        {/*      <VideoItem video={video} onClick={this.playVideo} />*/}
        {/*    </CarouselSlide>*/}
        {/*  ))}*/}
        {/*</Carousel>*/}
      </div>
    )
  }
}
